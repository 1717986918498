// DO NOT EDIT MANUALLY! This file is generated from Airtable: "Earn-quests"

import { Value } from './earnQuests.types';

// prettier-ignore
const earnQuests: Value[] = [
  {
    "actionCTA": "connect_wallet",
    "category": "special",
    "checkCTA": "check_word",
    "icon": "earn_wallet_connect100",
    "id": "connect_wallet",
    "migrationKey": "walletConnect",
    "reward": 10000,
    "test": true
  },
  {
    "actionCTA": "join_word",
    "analytics": "join_announcement",
    "category": "special",
    "checkCTA": "check_word",
    "icon": "earn_announcement",
    "iconUrl": "https://notgemz.cms.gemz.fun/media/powerups/telegram_logo_512.png",
    "id": "announcement_channel",
    "migrationKey": "joinAnnouncement",
    "order": 1,
    "reward": 10000,
    "url": "https://t.me/gemz_announcements"
  },
  {
    "actionCTA": "follow_word",
    "category": "special",
    "checkCTA": "check_word",
    "icon": "youtube",
    "id": "follow_youtube",
    "migrationKey": "followOnYoutube",
    "order": 2,
    "reward": 25000,
    "url": "https://www.youtube.com/@gemzfun"
  },
  {
    "actionCTA": "follow_word",
    "category": "special",
    "checkCTA": "check_word",
    "icon": "earn_x",
    "id": "follow_x",
    "migrationKey": "followOnX",
    "order": 3,
    "reward": 10000,
    "url": "https://x.com/gemzfun"
  },
  {
    "actionCTA": "follow_word",
    "category": "special",
    "checkCTA": "check_word",
    "icon": "earn_x",
    "id": "follow_x_jw",
    "migrationKey": "followOnXJW",
    "order": 4,
    "reward": 10000,
    "url": "https://x.com/jtwald"
  },
  {
    "actionCTA": "follow_word",
    "category": "special",
    "checkCTA": "check_word",
    "icon": "ig",
    "id": "join_instagram",
    "migrationKey": "joinInstagram",
    "order": 5,
    "reward": 10000,
    "url": "https://www.instagram.com/gemzfun/"
  },
  {
    "actionCTA": "follow_word",
    "category": "special",
    "checkCTA": "check_word",
    "icon": "tiktok",
    "id": "join_tiktok",
    "migrationKey": "joinTiktok",
    "order": 6,
    "reward": 10000,
    "url": "https://www.tiktok.com/@gemzfun"
  },
  {
    "actionCTA": "team_invite_button",
    "analytics": "2_friends",
    "category": "bonus",
    "checkCTA": "check_word",
    "icon": "earn_invite2",
    "id": "invite_2",
    "migrationKey": "inviteFriends2",
    "order": 1,
    "reward": 40000
  },
  {
    "actionCTA": "team_invite_button",
    "analytics": "5_friends",
    "category": "bonus",
    "checkCTA": "check_word",
    "icon": "earn_invite5",
    "id": "invite_5",
    "migrationKey": "inviteFriends5",
    "order": 3,
    "reward": 150000
  },
  {
    "actionCTA": "team_invite_button",
    "analytics": "10_friends",
    "category": "bonus",
    "checkCTA": "check_word",
    "icon": "earn_invite10",
    "id": "invite_10",
    "migrationKey": "inviteFriends10",
    "order": 5,
    "reward": 300000
  },
  {
    "actionCTA": "team_invite_button",
    "analytics": "100_friends",
    "category": "bonus",
    "checkCTA": "check_word",
    "icon": "earn_invite100",
    "id": "invite_100",
    "migrationKey": "inviteFriends100",
    "order": 5,
    "reward": 3000000
  },
  {
    "actionCTA": "buy_meme",
    "analytics": "buy_meme",
    "category": "video",
    "checkCTA": "check_word",
    "icon": "meme",
    "id": "buy_meme",
    "order": 7,
    "reward": 250000
  },
  {
    "actionCTA": "create_meme",
    "analytics": "create_meme",
    "category": "video",
    "checkCTA": "check_word",
    "icon": "meme",
    "id": "create_meme",
    "order": 7,
    "reward": 5000000
  },
  {
    "actionCTA": "react_post",
    "category": "video",
    "checkCTA": "check_word",
    "endTime": 1740092400000,
    "icon": "earn_x",
    "id": "X_x170_2025-02-13",
    "order": 20,
    "reward": 50000,
    "startTime": 1739487600000,
    "url": "https://go.gemz.fun/6nr64V"
  }
];
export default earnQuests;
